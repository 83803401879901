import React from 'react'
import styled from 'styled-components'
import SEO from '../components/base/SEO'
import Layout from '../components/base/Layout'
import AboutIntroSection from '../components/about/AboutIntroSection'
import ValueSection from '../components/about/ValueSection'
import GoalSection from '../components/about/GoalSection'
import { down, only, up } from 'styled-breakpoints'

const metaData = {
	title: 'About | Tesser',
	description: '환자를 위한 서비스와 인공지능 기술로 의료 생태계를 혁신합니다. ',
	keywords:
		'테서, tesser, 의학연구, 의학논문, 데이터바우처, AI바우처, 의료 스타트업, 메디컬AI, 혁신, 환자 서비스, 의료 LLM, 의료 데이터, 환자 데이터, 인공지능 기업, 의료 인공지능',
}

const About = () => (
	<Layout blackFooter>
		<SEO metaData={metaData} />
		<Block>
			<AboutIntroSection />
			<ValueSection />
			<GoalSection />
		</Block>
	</Layout>
)

const Block = styled.div`
	max-width: 1920px;
	margin: 0 auto;

	> * {
		${up('xl')} {
			margin-bottom: 160px;
		}
		${only('lg')} {
			margin-bottom: 160px;
		}
		${only('md')} {
			margin-bottom: 160px;
		}
		${only('sm')} {
			margin-bottom: 80px;
		}
		${down('xs')} {
			margin-bottom: 40px;
		}
	}

	${up('xl')} {
		margin-bottom: 160px;
	}
	${only('lg')} {
		margin-bottom: 160px;
	}
	${only('md')} {
		margin-bottom: 160px;
	}
	${only('sm')} {
		margin-bottom: 80px;
	}
	${down('xs')} {
		margin-bottom: 40px;
	}
`

export default About
