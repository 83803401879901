import React from 'react'
import styled from 'styled-components'
import { up, down, only } from 'styled-breakpoints'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { useTranslation } from 'react-i18next'

import Typography from '../common/Typography'
import { calculateGridWidth } from '../../lib/utils/calculateGridWidth'

function ValueSection() {
	const breakpoints = useBreakpoint()

	const { t } = useTranslation()

	return (
		<Block>
			<Typography.Heading
				className="mainTitle"
				size="xxlarge"
				fontWeight="bold"
			>
				{breakpoints.lg ? (
					<>{t('ValueSection.heading1')}</>
				) : (
					<>{t('ValueSection.heading2')}</>
				)}
			</Typography.Heading>
			<FigureWrapper>
				<FigureItem
					title={t('ValueSection.title1')}
					value={t('ValueSection.value1')}
					description={t('ValueSection.description1')}
				/>
				<FigureItem
					title={t('ValueSection.title2')}
					value={t('ValueSection.value2')}
					description={t('ValueSection.description2')}
				/>
				<FigureItem
					title={t('ValueSection.title3')}
					value={t('ValueSection.value3')}
					description={t('ValueSection.description3')}
				/>
				<FigureItem
					title={t('ValueSection.title4')}
					value={t('ValueSection.value4')}
					description={t('ValueSection.description4')}
				/>
			</FigureWrapper>
		</Block>
	)
}

const Block = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 40px;
	padding-bottom: 40px;

	${up('xl')} {
		padding-left: 104px;
		padding-right: 104px;
	}

	${only('md')} {
		padding-left: 120px;
		padding-right: 120px;
	}

	> .mainTitle {
		margin-left: 0;

		${only('lg')} {
			margin-left: 104px;
		}

		${down('md')} {
			margin-bottom: 5rem;
		}
	}

	${down('md')} {
		flex-direction: column;
	}
`

const FigureWrapper = styled.section`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-row-gap: 80px;
	grid-column-gap: 136px;

	${down('lg')} {
		grid-column-gap: 32px;
		grid-row-gap: 64px;
	}

	${down('xs')} {
		grid-row-gap: 40px;
	}
`

interface FigureItemProps {
	title: string
	value: string
	description: string
}

const FigureItem = ({ title, value, description }: FigureItemProps) => {
	return (
		<FigureItemBlock>
			<Typography.Body size="large" fontWeight="bold" className="title">
				{title}
			</Typography.Body>
			<Typography.Display size="xxlarge" fontWeight="bold" className="value">
				{value.includes('%') ? (
					<>
						<span>{value.split('%')[0]}</span>
						<span className="percentage">%</span>
					</>
				) : (
					<>{value}</>
				)}
			</Typography.Display>
			<Typography.Body size="medium" className="description">
				{description}
			</Typography.Body>
		</FigureItemBlock>
	)
}

const FigureItemBlock = styled.div`
	width: ${calculateGridWidth(4)};

	${only('md')} {
		width: ${calculateGridWidth(3, true)};
	}

	${only('sm')} {
		width: ${calculateGridWidth(3, true)};
	}

	${down('xs')} {
		width: 100%;
	}

	> .title {
		margin-bottom: 16px;
	}

	> .value {
		${down('xs')} {
			font-size: 48px;
			line-height: 56px;
		}
	}

	> .description {
		margin-top: 16px;
	}

	.percentage {
		font-size: 2rem;
		font-weight: 700;
		line-height: 2.5rem;
	}
`

export default ValueSection
