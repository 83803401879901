import React from 'react'
import { down, only, up } from 'styled-breakpoints'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import Typography from '../common/Typography'
import { calculateGridWidth } from '../../lib/utils/calculateGridWidth'

function GoalSection() {
	const { t } = useTranslation()
	const breakpoints = useBreakpoint()

	return (
		<Block>
			<Typography.Display size="large" fontWeight="bold" className="mainTitle">
				{breakpoints.md ? (
					<>{t('GoalSection.heading2')} </>
				) : (
					<>{t('GoalSection.heading1')}</>
				)}
			</Typography.Display>
			<Articles>
				<ArticleBox>
					<Typography.Heading
						size="small"
						fontWeight="bold"
						className="subTitle"
					>
						{t('GoalSection.title1')}
					</Typography.Heading>
					<Typography.Heading size="xsmall">
						{t('GoalSection.text1')}
					</Typography.Heading>
				</ArticleBox>
				<ArticleBox className="secondBox">
					<Typography.Heading
						size="small"
						fontWeight="bold"
						className="subTitle"
					>
						{t('GoalSection.title2')}
					</Typography.Heading>
					<Typography.Heading size="xsmall">
						{t('GoalSection.text2')}
					</Typography.Heading>
				</ArticleBox>
			</Articles>
		</Block>
	)
}

const Block = styled.div`
	padding-top: 80px;
	padding-bottom: 80px;

	${up('xl')} {
		padding-left: 104px;
		padding-right: 104px;
	}

	${only('lg')} {
		padding-left: 104px;
	}

	${only('md')} {
		padding-left: 120px;
		padding-right: 120px;
	}

	> .mainTitle {
		${down('xs')} {
			font-size: 36px;
			line-height: 44px;
		}
	}
`

const Articles = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 80px;

	${down('xs')} {
		margin-top: 40px;
	}

	.secondBox {
		margin-left: 32px;
	}

	${up('xl')} {
		padding-left: ${calculateGridWidth(5)};
	}

	${only('lg')} {
		padding-left: 104px;
	}

	${only('md')} {
		justify-content: flex-start;
	}

	${only('sm')} {
		justify-content: flex-start;
	}

	${down('xs')} {
		flex-direction: column;
	}
`

const ArticleBox = styled.span`
	flex: 1;
	.subTitle {
		margin-bottom: 16px;
	}

	${up('xl')} {
		min-width: ${calculateGridWidth(5)};
	}

	${only('md')} {
		min-width: ${calculateGridWidth(5)};
	}

	${only('md')} {
		min-width: ${calculateGridWidth(3, true)};
	}

	${only('sm')} {
		min-width: ${calculateGridWidth(3, true)};
	}

	${down('xs')} {
		width: 100%;

		&:last-child {
			margin-top: 40px;
			margin-left: 0;
		}
	}
`

export default GoalSection
