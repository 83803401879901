import React from 'react'
import styled from 'styled-components'
import { up, down, only } from 'styled-breakpoints'
import { useTranslation } from 'react-i18next'

import Typography from '../common/Typography'
import GradientCanvas from '../main/GradientCanvas'
import zIndexes from '../../lib/styles/zIndexes'

function AboutIntroSection() {

	const gradientColors = {
		color1: '#FF275B ',
		color2: '#AD00FF',
		color3: '#FF6BD6',
		color4: '#FF36AF',
	}

	const { t } = useTranslation()

	return (
		<Block>
			<Typography.Display className="title" size="xxlarge" fontWeight="bold">
				{t('AboutIntroSection.heading1')}
			</Typography.Display>
			<GradientContainer
				data-sal="zoom-out"
				data-sal-delay="100"
				data-sal-duration="600"
			>
				<GradientCanvas hasShape={false} gradientColors={gradientColors} />
			</GradientContainer>
		</Block>
	)
}

const Block = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 204px;
	padding-bottom: 40px;
	height: 100%;
	${down('xs')} {
		margin-top: 120px;
	}

	${down('xxs')} {
		align-items: flex-start;
	}

	> .title {
		align-self: flex-start;
		margin: 0 auto;
		max-height: 21rem;

		${only('lg')} {
			font-size: 96px;
			line-height: 112px;
		}
		${only('md')} {
			font-size: 72px;
			line-height: 88px;
		}
		${only('sm')} {
			font-size: 64px;
			line-height: 72px;
		}
		${down('xs')} {
			font-size: 48px;
			line-height: 56px;
		}
		${down('md')} {
			padding-left: 0;
			max-height: 18.5rem;
		}
		${down('xs')} {
			max-height: 14.5rem;
		}

		${down('xxs')} {
			margin: 0;
			padding-left: 40px;
		}
	}

	.titleSkeleton {
		${down('xs')} {
			margin-bottom: 400px;
		}
	}
`

const GradientContainer = styled.section<{ squareSize: number }>`
	position: relative;
	z-index: ${zIndexes.gradient};
	overflow: hidden;

	${up('xl')} {
		margin-top: -60px;
	}

	${only('lg')} {
		margin-top: -48px;
	}

	${only('md')} {
		margin-top: -40px;
	}

	${only('sm')} {
		margin-top: -26px;
		width: 448px;
		height: 448px;
	}

	${only('xs')} {
		margin-top: -24px;
		width: 307px;
		height: 307px;
	}

	${down('xxs')} {
		margin-top: -24px;
		width: 100%;
		padding: 0px 40px;
		padding-bottom: 135px;
		height: 100vw;
		box-sizing: border-box;
	}

	canvas {
		${up('xl')} {
			width: 592px;
			height: 592px;
		}
		${only('lg')} {
			width: 592px;
			height: 592px;
		}
		${only('md')} {
			width: 448px;
			height: 448px;
			max-height: 448px;
		}
		${down('sm')} {
			width: 448px;
			height: 448px;
		}
		${down('xxs')} {
			width: 100%;
			height: 100%;
		}
	}
`

export default AboutIntroSection
